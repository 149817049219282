import { findLayoutVoyoWidgetIds, preloadVoyoSliders } from '@hmn/rtl-web-core/components/VoyoSlider/VoyoSliderSSR'
import { preloadWidgetData } from '@hmn/rtl-web-core/helpers/layout-builder/preload-widget-data'
import { serialize } from '@hmn/rtl-web-core/helpers/serialize'
import { createDataClient } from '@hmn/rtl-web-core/hooks'
import { adsGetServerSidePropsFactory } from '@hmn/rtl-web-core/next/data/adsGetServerSideProps'
import { commonGetServerSideProps } from '@hmn/rtl-web-core/next/data/commonGetServerSideProps'
import combineGetServerSideProps from '@hmn/rtl-web-core/next/helpers/combineGetServerSideProps'

import { insertAds as insertAdsConfig, staticAds } from '@hmn/rtl-net-ui/components/Ad/config/listing'

import { componentGetDataMap } from '../components/LayoutBuilder/components/WidgetComponents.data'
import { ComponentType } from '../components/LayoutBuilder/helpers'
import { Category } from '../components/Layouts/CategoryLayout'
import { TaboolaScript } from '../components/Taboola.component'
import { euIzboriGetServerSideProps } from '../helpers/euIzboriGetSSP'

const { halfpage1, outOfPage1, outOfPage2, outOfPage3, interstitial } = staticAds

export default function Home({ ...props }) {
    return (
        <>
            <Category {...props} />
            <TaboolaScript />
        </>
    )
}

const categoryGetServerSideProps = async ({ req }) => {
    const dataClient = createDataClient({ req })
    const { getData } = dataClient

    const { data: category } = await getData({
        resource: `category/find`,
        params: {
            query: {
                id: process.env.NEXT_PUBLIC_HOMEPAGE_ID_NET
            }
        }
    })

    const categoryDataResult = await getData({
        resource: `category/${category.id}/layout`
    })

    const categoryLayout = {
        ...categoryDataResult.data,
        widgetList: await preloadWidgetData(
            categoryDataResult.data.widgetList,
            componentGetDataMap,
            ComponentType,
            categoryDataResult.data.entityList
        )
    }
    const voyoIds = findLayoutVoyoWidgetIds(categoryLayout?.widgetList) || []
    const voyoSliders = await preloadVoyoSliders(voyoIds)

    return {
        props: {
            category: serialize(category),
            categoryLayout: serialize(categoryLayout),
            categoryEntities: null,
            datePickerData: null,
            voyoSliders: serialize(voyoSliders),
            isLayout: true,
            isListing: false,
            slugs: [],
            gemiusId: category.extended_attributes?.gemius_id || process.env.NEXT_PUBLIC_GEMIUS_ID_NET,
            categoryColor: category.extended_attributes?.category_color || null
        }
    }
}

export const getServerSideProps = combineGetServerSideProps([
    categoryGetServerSideProps,
    euIzboriGetServerSideProps({
        loadResults: true,
        loadPoll: true,
        loadCandidates: true
    }),
    commonGetServerSideProps({ cache: { maxAge: 15, sMaxAge: 30, staleWhileRevalidate: 60 } }),
    adsGetServerSidePropsFactory({
        pageType: 'category',
        zones: { halfpage1, outOfPage1, outOfPage2, outOfPage3, interstitial },
        insertAdsConfig,
        pageId: process.env.NEXT_PUBLIC_HOMEPAGE_ID_NET
    })
])
